import React from 'react';
// import {
//   DesktopOutlined,
//   SettingOutlined,
//   CustomerServiceOutlined,
//   FileTextOutlined,
//   FileSyncOutlined,
//   DashboardOutlined,
//   TeamOutlined,
//   UserOutlined,
//   CreditCardOutlined,
//   BankOutlined,
// } from "@ant-design/icons";

// export const IconMenu = ({ name }) => {
//   const components = {
//     DesktopOutlined: DesktopOutlined,
//     SettingOutlined: SettingOutlined,
//     CustomerServiceOutlined: CustomerServiceOutlined,
//     FileTextOutlined: FileTextOutlined,
//     FileSyncOutlined: FileSyncOutlined,
//     DashboardOutlined: DashboardOutlined,
//     TeamOutlined: TeamOutlined,
//     UserOutlined: UserOutlined,
//     CreditCardOutlined: CreditCardOutlined,
//     BankOutlined: BankOutlined,
//     Default: DesktopOutlined,
//   };

//   const IconTag = components[name || "Default"] || SettingOutlined;
//   return <IconTag />;
// };

export const routesConfig = [
  // {
  //   path: '/',
  //   component: 'Dashboard',
  // },
  {
    path: '/',
    component: 'AttendanceReport',
  },
  {
    path: '/lead',
    component: 'Customer',
  },
  {
    path: '/invoice',
    component: 'Invoice',
  },
  {
    path: '/projecttracking',
    component: 'Quote',
  },
  {
    path: '/maintenance',
    component: 'MaintenancePlan',
  },
  {
    path: '/payment/invoice',
    component: 'PaymentInvoice',
  },
  {
    path: '/employees',
    component: 'Employees/Employees',
  },
  {
    path: '/employees/edit/:employeeId',
    component: 'Employees/EmployeeEdit',
  },
  {
    path: '/employees/show/:employeeId',
    component: 'Employees/EmployeeShow',
  },
  {
    path: '/recruitment',
    component: 'Recruitment/Recruitment',
  },
  {
    path: '/candidate/:candidateId',
    component: 'Recruitment/RecruitmentEdit',
  },
  {
    path: '/ai-text-detector',
    component: 'AITextDetector',
  },
  {
    path: '/admin',
    component: 'Admin',
  },
  {
    path: '/settings',
    component: 'Settings/Settings',
  },
  {
    path: '/payment/mode',
    component: 'PaymentMode',
  },
  {
    path: '/role',
    component: 'Role',
  },
  {
    path: '/proposal',
    component: 'Proposal',
  },
  {
    path: '/proposals/all-proposals',
    component: 'AllProposals',
  },
  {
    path: '/edit-proposal/:id', // Add this for editing proposals
    component: 'EditProposal',
  },
  {
    path: '/daily-activity-log',
    component: 'AttendanceReportsHR/AttendanceLog',
  },

  {
    path: '/time-in-records',
    component: 'AttendanceReportsHR/TimeInRecords',
  },
  {
    path: '/attendance-report',
    component: 'AttendanceReportsHR/MonthlySummary',
  },
  {
    path: '/extra-hours-logs',
    component: 'AttendanceReportsHR/ExtraHoursLogs',
  },
  {
    path: '/complaint',
    component: 'Complaint',
  },
  // {
  //   path: '/attendance-dashboard',
  //   component: 'AttendanceCard',
  // },
  {
    path: '/leaves',
    component: 'LeavesForm',
  },
  {
    path: '/suggestions&complaints',
    component: 'SuggestionsComplaintForm',
  },
  {
    path: '/activity-scorecard',
    component: 'ActivityScoreCard',
  },
  {
    path: '/voice-overs',
    component: 'VoiceOvers',
  },
  {
    path: '/colorpicker',
    component: 'ColorPicker',
  },
  {
    path: '/meta-length-checker',
    component: 'MetaLengthChecker',
  },
  {
    path: '/page-speed-insights',
    component: 'PageSpeedInsights',
  },
];
